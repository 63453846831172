import { useAlert, useLanguage } from '@infominds/react-native-components'
import { AuthConfig, AuthenticationContainer } from '@infominds/react-native-license'
import React, { ReactNode } from 'react'
import BootSplash from 'react-native-bootsplash'
import { useSetRecoilState } from 'recoil'

import { DataProviderContextProvider } from '../dataProvider/context/DataProviderContext'
import { apiVersionAtom } from '../utils/stateManager'
import version from '../versions.json'

type Props = { children: ReactNode | (({ authReady, isLoggedIn }: { authReady: boolean; isLoggedIn: boolean }) => ReactNode) }

const authConfig: AuthConfig = {
  aad: false,
  apiType: '.net',
  projectCode: ['APP-EMSRV', 'APP-EMSRVREP'],
  moduleCode: 'APP',
  debug: __DEV__,
}
export default function AuthenticationWrapper({ children }: Props) {
  const { language } = useLanguage()
  const { alert } = useAlert()

  const setApiVersion = useSetRecoilState(apiVersionAtom)

  return (
    <AuthenticationContainer
      config={authConfig}
      locale={language}
      versionsFile={version}
      onVersionCheckEnd={({ error, apiVersion }) => {
        setApiVersion(apiVersion)
        error && alert(error.title, error.message)
      }}>
      {authContext => {
        const authReady = authContext && authContext.isLoggedIn !== undefined

        authReady && BootSplash.hide().catch(console.error)

        if (authContext === undefined || authReady === undefined || authContext.isLoggedIn === undefined) return <></>

        return (
          <DataProviderContextProvider>
            {typeof children === 'function' ? children({ authReady, isLoggedIn: authContext.isLoggedIn }) : children}
          </DataProviderContextProvider>
        )
      }}
    </AuthenticationContainer>
  )
}
